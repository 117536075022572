/*
 * The NgModule for the company-profile-form component.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyProfileFormComponent } from './company-profile-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbInputModule } from '@nebular/theme';

@NgModule({
  declarations: [CompanyProfileFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NbInputModule
  ],
  exports:[
    CompanyProfileFormComponent
  ]
})
export class CompanyProfileFormModule { }
