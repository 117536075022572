import { Injectable } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';

@Injectable({ providedIn: 'root' })
export class ConfigService {
    constructor() { }

    offlineMode = false;

    //TO DO: create a new re-captca account and set domain to iris.imvrcanada.ca
    //rcKey = "6Lfb8YcUAAAAAIyMPT5RO1-2RGFjmrP3JxGKqbmh";
    rcKey = "6LfVHsAbAAAAALHViuZelgQ2cuVioUOysP8qUmC7";

    displayNamesUser =
        {
            normal: 'driver',
            plural: 'drivers',
            cap: 'Driver',
            capPlural: 'Drivers'
        };

    displayNamesAdmin =
        {
            normal: 'admin',
            plural: 'admins',
            cap: 'Administrator',
            capPlural: 'Administrators'
        };

    displayNamesCompany =
        {
            normal: 'company',
            plural: 'companies',
            cap: 'Company',
            capPlural: 'Companies'
        };

    consoleMenuItemsUser: NbMenuItem[] =
        [
            {
                title: '',
                group: true
            },
            {
                title: 'Home',
                icon: 'home',
                link: '/console/home',
                home: true
            },

            {
                title: 'Account',
                group: true
            },
            {
                title: this.displayNamesUser.cap + ' Profile',
                icon: 'person',
                link: '/console/user-profile'
            },

            {
                title: 'Training',
                group: true
            },
            {
                title: 'Training Tasks',
                icon: 'list',
                link: '/console/training-tasks/tasklists'
            },

            {
                title: 'Review',
                group: true
            },
            {
                title: 'Replay',
                icon: 'video',
                link: '/console/user-videos'
            },
            {
                title: 'Air Brakes Test',
                icon: 'star',
                link: '/console/user-scores/user-airbrakes'
            },
            {
                title: 'Circle Check Score',
                icon: 'checkmark-circle-outline',
                link: '/console/user-scores/user-circle-check-list'
            },
            {
                title: 'Coupling Score',
                icon: 'link-outline',
                link: '/console/user-scores/user-coupling-scores'
            },
            {
                title: 'Driving Score',
                icon: 'car-outline',
                link: '/console/user-scores/user-score-list'
            },
            {
                title: 'Eye Tracking',
                icon: 'eye',
                link: '/console/user-scores/user-eye-track'
            },
            {
                title: 'Lane Tracking',
                icon: 'shuffle-2',
                link: '/console/user-scores/user-lane-track'
            },
            {
                title: 'Parking Score',
                icon: 'menu-arrow',
                link: '/console/user-scores/user-park-scores'
            },
            {
                title: 'Skidding Score',
                icon: 'undo-outline',
                link: '/console/user-scores/user-skid-scores'
            },
            {
                title: 'Stop Tracking',
                icon: 'pause-circle-outline',
                link: '/console/user-scores/user-stop-track'
            },
            {
                title: 'Notes',
                icon: 'edit-2',
                link: '/console/user-notes/user-note-list'
            },

            /*{
                title: 'Security',
                group: true
            },
            {
                title: 'Reset Password',
                icon: 'fas fa-key',
                link: '/console/reset-password'
            }*/
        ];

    consoleMenuItemsAdmin: NbMenuItem[] =
        [
            {
                title: '',
                group: true
            },
            {
                title: 'Home',
                icon: 'home',
                link: '/console/home',
                home: true
            },

            {
                title: 'Account',
                group: true
            },
            {
                title: this.displayNamesAdmin.cap + ' Profile',
                icon: 'globe',
                link: '/console/admin-profile'
            },
            {
                title: 'Manage ' + this.displayNamesUser.capPlural,
                icon: 'people',
                link: '/console/manage-users/datatable'
            },
            {
                title: 'Training',
                group: true
            },
            {
                title: 'Manage Tasks',
                icon: 'checkmark-square-2',
                link: '/console/manage-tasks/tasks'
            },
            {
                title: 'Manage ' + this.displayNamesUser.capPlural + ' Tasks',
                icon: 'clipboard',
                link: '/console/manage-users-training-tasks/users-table'
            }

            /*{
                title: 'Security',
                group: true
            },
            {
                title: 'Reset Password',
                icon: 'fas fa-key',
                link: '/console/reset-password'
            }*/
        ];

    consoleMenuItemsCompany: NbMenuItem[] =
        [
            {
                title: '',
                group: true,
            },
            {
                title: 'Home',
                icon: 'home',
                link: '/console/home',
                home: true
            },

            {
                title: 'Account',
                group: true
            },
            {
                title: this.displayNamesCompany.cap + ' Profile',
                icon: 'car',
                link: '/console/company-profile'
            },
            {
                title: 'Manage ' + this.displayNamesAdmin.capPlural,
                icon: 'settings',
                link: '/console/manage-admins/admins-table'
            }
        ];

    consoleMenuItemsDeveloper: NbMenuItem[] =
        [
            {
                title: 'Developer Tools',
                group: true,
            },
            {
                title: 'New Users',
                icon: 'person-add',
                link: '/console/manage-companies/new-users'
            },
            {
                title: 'iLRN',
                icon: 'skip-forward-outline',
                link: '/console/manage-users-training-tasks/quick-start-tasks'
            },
            {
                title: 'Manage ' + this.displayNamesCompany.capPlural,
                icon: 'settings',
                link: '/console/manage-companies/companies-table'
            },
            {
                title: 'Login Tracking',
                icon: 'checkmark-circle-2',
                link: '/console/manage-companies/login-tracking'
            },
            {
                title: 'Who\'s Online Now',
                icon: 'globe-2',
                link: '/console/manage-companies/who-is-online-now'
            },
            {
                title: 'Monitor Usage',
                icon: 'person-done-outline',
                link: '/console/manage-companies/monitor-usage'
            },
            {
                title: 'Iris Version',
                icon: 'info',
                link: '/console/manage-companies/iris-version'
            },
            {
                title: 'Search Videos',
                icon: 'video',
                link: '/console/manage-companies/search-replays'
            }
        ];

    consoleMenuItemsWTFC: NbMenuItem[] =
        [
            {
                title: 'WTFC Tools',
                group: true,
            },
            {
                title: 'iLRN',
                icon: 'skip-forward-outline',
                link: '/console/manage-users-training-tasks/quick-start-tasks'
            },
            {
                title: 'Who\'s Online Now',
                icon: 'globe-2',
                link: '/console/manage-companies/who-is-online-now'
            },
            {
                title: 'Monitor Usage',
                icon: 'person-done-outline',
                link: '/console/manage-companies/monitor-usage'
            },
            {
                title: 'Search Videos',
                icon: 'video',
                link: '/console/manage-companies/search-replays'
            }
        ];

    modalContentAwaitingServerResponse = '<div class="spinner-border mt-2" role="status"><span class="sr-only"></span></div> &nbsp; Waiting for server response...';
}
