import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { appServerProtocol, appServerIPAddress, appServerPort } from '../global';
import { catchError, map, delay } from 'rxjs/operators';
import { ProcessHTTPMsgService } from './process-httpmsg.service';
import { throwError } from 'rxjs';
import { simulatedServerResponseDelay } from 'src/app/global';
// interface AuthResponse {
//   status: string;
//   success: string;
//   token: string;
// }

// interface JWTResponse {
//   status: string;
//   success: string;
//   user: any;
// }

// export enum AccountType {
//   User,
//   Admin,
//   Company
// }

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // accountType: AccountType;

  constructor(private http: HttpClient,
    private processHTTPMsgService: ProcessHTTPMsgService) { }

  credentialsKey = 'authCredentials';
  isAuthenticated: Boolean = false;
  credentials;
  onLoadedCredentials: Subject<any> = new Subject<any>();

  logInAsAdmin(loginFormValue: any): Observable<any> {
    return this.http.post<any>(appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/admins/web/login',
      {
        //'adminID': loginFormValue.adminID,
        'email': loginFormValue.email,
        'password': loginFormValue.password,
        'g-recaptcha-response': loginFormValue.recaptchaReactive
      })
      .pipe( 
        map(res => {
          const credentials = {adminID: res.adminID, token: res.token}; //loginFormValue.adminID
    
          this.isAuthenticated = true;
          this.credentials = credentials;
          this.saveCredentials();
          return res;
        }),
        
        catchError(error => this.processHTTPMsgService.handleError(error)),
        delay(simulatedServerResponseDelay)
        //catchError(error => throwError(error))
      );
  }

  logInAsUser(loginFormValue: any): Observable<any> {
    return this.http.post<any>(appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/users/web/login',
      {
        //'userID': loginFormValue.userID,
        'email': loginFormValue.email,
        'password': loginFormValue.password,
        'g-recaptcha-response': loginFormValue.recaptchaReactive
      })
      .pipe( 
        map(res => {
          const credentials = {userID: res.userID, token: res.token}; //loginFormValue.userID
          
          this.isAuthenticated = true;
          this.credentials = credentials;
          this.saveCredentials();
          return res;
        }),
        catchError(error => this.processHTTPMsgService.handleError(error))
      );
      // catchError(error => throwError(error)));
  }

  logInAsCompany(loginFormValue: any): Observable<any> {
    return this.http.post<any>(appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/companies/web/login',
      {
        'softwareKey': loginFormValue.softwareKey,
        'g-recaptcha-response': loginFormValue.recaptchaReactive
      })
      .pipe( 
        map(res => {
          const credentials = {
            softwareKey: loginFormValue.softwareKey, 
            token: res.token,
            companyID: res.companyID
          };
          
          this.isAuthenticated = true;
          this.credentials = credentials;
          this.saveCredentials();

          return res;
        }),
        delay(simulatedServerResponseDelay),
        catchError(error => this.processHTTPMsgService.handleError(error))
      );
  }

  logOut(){
    this.destroyCredentials();
  }

  saveCredentials() {

    localStorage.setItem(this.credentialsKey, JSON.stringify(this.credentials));

  }
  
  loadCredentials(): Observable<boolean> {
    //console.log('AuthService.loadCredentials');

    const credentialsStr = localStorage.getItem(this.credentialsKey);

    if (credentialsStr == null || credentialsStr == 'undefined'){
      this.destroyCredentials();
      
      return of(false);
    }

    const credentials = JSON.parse(credentialsStr);
    
    if (credentials == null || credentials.token == null){
      this.destroyCredentials();
      return of(false);
    }

    return this.checkCredentials(credentials)
    .pipe(
      map(res => {
        //console.log(res);
        if(res.statusCode!='200'){
     
          this.destroyCredentials();
          return false;
        }

        this.isAuthenticated = true;
        this.credentials = credentials;

        return true;
      }),
      catchError(error => {
        //console.log(error);
        this.destroyCredentials();
        throw(error);
      })
    );
    // .subscribe(res => {
    //   this.isAuthenticated = true;
    //   this.credentials = credentials;
    //   console.log('Auth credentials validated!');
    //   console.log(res);
    //   this.onLoadedCredentials.next(credentials);
    // },
    // err=>{
    //   // console.log('Invalid auth credentials!');
    //   // console.log(err);
    //   this.credentials = null;
    //   this.isAuthenticated = false;
    //   localStorage.removeItem(this.credentialsKey);
    // });
    // this.isAuthenticated = true;
    // this.credentials = credentials;

  }

  checkCredentials(credentials) {

    var url;
    if (credentials.userID != null){
      url = appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/users/web/checkAuthCredentials';
    }
    else if (credentials.adminID != null){
      url = appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/admins/web/checkAuthCredentials';
    }
    else if (credentials.companyID != null){
      url = appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/companies/web/checkAuthCredentials';
    }

    const postData = credentials;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    return this.http.post<any>(url, postData, httpOptions)
    .pipe(
      map(res => {
        return res;
      }),
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  isLoggedIn(): Boolean {
    return this.isAuthenticated;
  }
  
  getCredentials() {
    //console.log('AuthService.getCredentials');
    return this.credentials;
  }

  destroyCredentials() {
    this.isAuthenticated = false;
    this.credentials = null;
    localStorage.removeItem(this.credentialsKey);
  }
}
