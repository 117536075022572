/*
 *  The NgModule for the company-setup component.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanySetupComponent } from './company-setup.component';
import { CompanyProfileFormModule } from 'src/app/console/company-profile-form/company-profile-form.module';
import { AlertModule } from 'src/app/shared/alert/alert.module';
import { FormsModule } from '@angular/forms';
import {
  NbStepperModule, 
  NbCardModule,
  NbCheckboxModule,
  NbLayoutModule,
  NbButtonModule
} from '@nebular/theme';

import { MessageModule } from 'src/app/shared/message/message.module';
import { MessageComponent } from 'src/app/shared/message/message.component';


@NgModule({
  declarations: [CompanySetupComponent],
  imports: [
    
    CommonModule,
    CompanyProfileFormModule,
    FormsModule,

    AlertModule,
    MessageModule,
    
    NbCheckboxModule,
    NbCardModule,
    NbStepperModule,
    NbLayoutModule,
    NbButtonModule
  ]
})

export class CompanySetupModule { }
