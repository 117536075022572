import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  // @Input()
  title;

  // @Input()
  content;

  // @Input()
  buttonsConfig;

  buttonsState;

  // @Output() 
  // close: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    $('#myModal').on('show.bs.modal', function (e) {
      console.log('on show message modal');
    })

    $('#myModal').on('shown.bs.modal', function (e) {
      console.log('on shown message modal');
    })

    $('#myModal').on('hide.bs.modal', function (e) {
      console.log('on hide message modal');
    })

    $('#myModal').on('hidden.bs.modal', function (e) {
      console.log('on hidden message modal');
    })
  }

  showModal(config=null){
    if(config){
      this.title = config.title;
      this.content = config.content;
      this.buttonsConfig = config.buttonsConfig;   

      if(config.fade){
        $('#modal-message').addClass('fade');
      }
      else{
        $('#modal-message').removeClass('fade');
      }
      
      if(config.options!=null){
        $('#modal-message').modal(config.options);
      }
    }

    this.resetButtonsState();

    $('#modal-message').modal('show');
  }

  hideModal(){
    
    $('#modal-message').modal('hide');
    // this.close.emit();
  }

  resetButtonsState(){
    this.buttonsState = [];
    
    if(this.buttonsConfig!=null){
      for(let i=0; i<this.buttonsConfig.length; i++){
        this.buttonsState[i] = {};
        this.buttonsState[i].disabled = false;
        this.buttonsState[i].loadingSpinner = false;
      }
    }
  }

  setButtonState(index, key, value){
    this.buttonsState[index][key] = value;
  }

}
