import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CompanySetupGuard implements CanActivate, CanActivateChild {

  constructor(
    private authService: AuthService,
    private router: Router,
    ) { 

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      console.log('CompanySetupGuard.canActivate');


      if(state.url=="/company-setup"){
        
        var authCredentials = this.authService.getCredentials();
        if(authCredentials!=null){
          return true;
        }

        return this.authService.loadCredentials()
        .pipe(
          map(res => {
            if(res==false) {
              this.router.navigate(["/auth/login"]);
              return false;
            }
  
            const authCredentials = this.authService.getCredentials();
  
            if(authCredentials==null){
              this.router.navigate(["/auth/login"]);
              return false;
            }
            
            return true;
          }),
          catchError(error => {
            
            this.router.navigate(["/auth/login"]);
            throw(error);
          })
        );
      }

      return true;
  
        
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  
}
