/*
 * The module that configures the router used in the root component. 
 */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CompanySetupComponent } from 'src/app/company-setup/company-setup.component';
import { CompanySetupGuard } from 'src/app/guards/company-setup.guard';

const routes: Routes = [
  // { path: 'dashboard', loadChildren: 'app/dashboard/dashboard.module#DashboardModule' },
  { 
    path: 'auth', 
    loadChildren: 'src/app/auth/auth.module#AuthModule' 
  },
  { 
    path: 'console', 
    loadChildren: 'src/app/console/console.module#ConsoleModule' 
  },
  { 
    path: 'company-setup',
    // loadChildren: 'src/app/company-setup/company-setup.module#CompanySetupModule' 
    canActivate: [CompanySetupGuard],
    component: CompanySetupComponent
  },

  { 
    path: '', 
    redirectTo: 'auth', 
    pathMatch: 'full' 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
