import { Component, OnInit, ViewChild } from '@angular/core';
import { NbAlertComponent } from '@nebular/theme';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @ViewChild(NbAlertComponent, { read: NbAlertComponent, static: true }) //ng v8 update
  nbAlertComponent: NbAlertComponent;

  isAlertHidden:boolean = true;
  alertText:string;

  constructor() { }

  ngOnInit() {
  }

  hideAlert(){
    this.isAlertHidden = true;
  }

  showAlert(text, status){
    this.alertText = text;
    this.nbAlertComponent.status = status;

    this.isAlertHidden = false;
  }

  // showSuccessTextAlert(text){
  //   this.alertText = text;
  //   this.nbAlertComponent.status = 'info';

  //   this.isAlertHidden = false;
  // }

  // showFailureTextAlert(text){
  //   this.alertText = text;
  //   this.nbAlertComponent.status = 'danger';

  //   this.isAlertHidden = false;
  // }

  showErrorAlert(error){
    if(error.error.errorMessage!=null){          
      this.showAlert('Error: '+error.error.errorMessage, 'danger');
    }else if(error.message!=null){
      this.showAlert('Error: '+error.message, 'danger');
    }else{
      this.showAlert('Error: '+error, 'danger');
    }
  }
}
