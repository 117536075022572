export const useHttps = true;
export const appServerProtocol = (useHttps ? "https" : "http");
export const appServerIPAddress = 'api.imvrcanada.ca';
//export const appServerIPAddress = '13.88.235.94';
export const appServerPort = (useHttps ? '3443' : '3000');
export const simulatedServerResponseDelay = 0;

declare global {
    interface DateConstructor {
        isLeapYear(year): boolean;
        getDaysInMonth(year, month): Number;
    }

    interface Date {
        isLeapYear(): boolean;
        getDaysInMonth(): Number;
        addMonths(value): Date;
        addYears(value): Date;
    }
}

Date.isLeapYear = function (year) { 
    return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)); 
};
  
Date.getDaysInMonth = function (year, month) {
    //return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];

    //0th day of month + 1 = last day of month
    return (new Date(year, month + 1, 0)).getDate();
};
  
Date.prototype.isLeapYear = function () { 
    return Date.isLeapYear(this.getFullYear()); 
};
  
Date.prototype.getDaysInMonth = function () { 
    return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
};
  
Date.prototype.addMonths = function (value) {
    var n = this.getDate();
    this.setDate(1);
    this.setMonth(this.getMonth() + value);
    this.setDate(Math.min(n, this.getDaysInMonth()));
    return this;
};
  
Date.prototype.addYears = function (value) {
    this.setFullYear(this.getFullYear()+value);
    return this;
};

export function getSubscriptionExpiryDate(startDate: Date, period)
{
    var expiryDate = startDate || new Date();
    try
    {
        var periodValue = Number(period.slice(0, -1));
        //console.log("periodValue: " + periodValue);

        var periodUnit = period.slice(-1);
        //console.log("periodUnit: " + periodUnit);

        if (periodUnit === 'm')
        {
            expiryDate.addMonths(periodValue);
        }
        else if (periodUnit === "y")
        {
            expiryDate.addYears(periodValue);
        }
    }
    catch (e)
    {
        console.log("Cannot set expiry date without start date AND period selected.")
    }

    return expiryDate;
}

export function dateToYYYYMMDDStr(date){

    var dd =date.getDate();
    var mm = date.getMonth() + 1; //January is 0!
    var yyyy = date.getFullYear();
    
    if (dd < 10) {
      dd = '0' + dd;
    } 
    if (mm < 10) {
      mm = '0' + mm;
    }
    
    return yyyy + '-' + mm + '-' + dd;
}

export function forceDateToUTC(strDate: string)
{
    var dt = new Date(strDate + "T00:00:00.000");

    var yyyy = dt.getFullYear();
    var mm = dt.getMonth() + 1; //January is 0!
    var dd = dt.getDate();

    var offset = dt.getTimezoneOffset() / 60;
    var mod = dt.getTimezoneOffset() % 60;

    var hh = dt.getHours() + offset;
    var mi = dt.getMinutes() + mod;

    return yyyy + "-" + ("0" + mm).slice(-2) + "-" + ("0" + dd).slice(-2) + "T" + ("0" + hh).slice(-2) + ":" + ("0" + mi).slice(-2) + ":00.000Z";
}

export const markFormGroupDirty = (formGroup) => {
(<any>Object).values(formGroup.controls).forEach(control => {
    control.markAsDirty();

    if (control.controls) {
    markFormGroupDirty(control);
    }
});
};

export const createGetDataURL = ( route, model, filter=null, projection:any[]=null, populate:any[]=null, options=null): string => {
    var url = appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/'+route+'/web/data?model='+model;
    if(filter!=null){
        url = url + '&filter=' + JSON.stringify(filter);
    }

    if(projection!=null && projection.length>0){
        url += '&select=';
        projection.forEach(obj=>{
        url+=obj;
        url+=',';
        });
        url = url.slice(0, -1);
    }

    if(populate!=null && populate.length>0){
        url+='&populate=';
        populate.forEach(obj=>{
        url+=JSON.stringify(obj);
        url+='|';
        });
        url = url.slice(0, -1);
    }

    if(options!=null){
        url = url + '&options=' + JSON.stringify(options);
    }

    return url;
}

export const addFormControlError = function(control, error: string) {
    if(control.errors==null){
      control.setErrors({});
    }

    control.errors[error] = true;
    // const err = control.errors; // get control errors
    // if (err) {
    //   delete err[error]; // delete your own error
    //   if (!Object.keys(err).length) { // if no errors left
    //     control.setErrors(null); // set control errors to null making it VALID
    //   } else {
    //     control.setErrors(err); // controls got other errors so set them back
    //   }
    // }
  }

export const removeFormControlError = function(control, error: string) {
    const err = control.errors; // get control errors
    if (err) {
      delete err[error]; // delete your own error
      if (!Object.keys(err).length) { // if no errors left
        control.setErrors(null); // set control errors to null making it VALID
      } else {
        control.setErrors(err); // controls got other errors so set them back
      }
    }
  }

export const shouldReuseRouteDefault = function(future, curr) {
    return future.routeConfig === curr.routeConfig;
};

export const shouldReuseRouteFalse = function(){
    return false;
};

export const getObjectValueArray= function(object): any[]{
    return Object.values(object);
}
