/*
 * The company-setup component where company sets up its profile after its first time login.
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { CompanyProfileFormComponent } from 'src/app/console/company-profile-form/company-profile-form.component';
import { NbStepperComponent, NbStepComponent } from '@nebular/theme/';
//import { NbStepComponent } from '@nebular/theme/components/stepper/step.component';
import { AlertComponent } from 'src/app/shared/alert/alert.component';
import { MessageComponent } from 'src/app/shared/message/message.component';

import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';

import { getSubscriptionExpiryDate } from 'src/app/global';
import { MediaObserver, MediaChange } from '@angular/flex-layout';

import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-company-setup',
  templateUrl: './company-setup.component.html',
  styleUrls: ['./company-setup.component.scss']
})
export class CompanySetupComponent implements OnInit {

  @ViewChild(CompanyProfileFormComponent, { read: null, static: true }) //ng v8 update
  form: CompanyProfileFormComponent;

  @ViewChild('stepper', {read: NbStepperComponent, static : true}) //ng v8 update
  stepper: NbStepperComponent;

  @ViewChild('stepSubscriptionInfo', { read: NbStepComponent, static: true }) //ng v8 update
  stepSubscriptionInfo: NbStepComponent;

  @ViewChild('stepBasicInfo', { read: NbStepComponent, static: true }) //ng v8 update
  stepBasicInfo: NbStepComponent;

  @ViewChild('alertBasicInfo', { read: AlertComponent, static: true }) //ng v8 update
  alertBasicInfo: AlertComponent;

  @ViewChild('alertSubscriptionInfo', { read: AlertComponent, static: true }) //ng v8 update
  alertSubscriptionInfo: AlertComponent;

  @ViewChild('modalMessage', { read: MessageComponent, static: true }) //ng v8 update
  private modalMessage: MessageComponent;

  confirmedSubscription;
  
  company;

  redirectCountdownInterval;
  redirectCountdownTimer = 3;

  isSubscriptionLoadingSpinnerShown;
  
  mediaChangeSubscription;
  activeMediaQuery;

  constructor(
    private companyService: CompanyService,
    private authService: AuthService,
    private router: Router,
    private mediaObserver: MediaObserver,
    private configService: ConfigService
  ) { 
   
  }

  ngOnInit() {
    this.form.createForm({}, {
      'view': 'company-setup'
    });

    const authCredentials = this.authService.getCredentials();

    this.isSubscriptionLoadingSpinnerShown = true;
    //setTimeout(()=>{
      this.companyService.getCompany(authCredentials.companyID, ['softwareKey', 'subscription', 'subscriptionStartDate'], ['softwareKey', 'subscription'])
      .subscribe(data=>{
  
        this.company = data;
        this.company.subscriptionExpiryDate = getSubscriptionExpiryDate(new Date(this.company.subscriptionStartDate), this.company.subscription.period);
        this.isSubscriptionLoadingSpinnerShown = false;
      },
      error=>{
        this.isSubscriptionLoadingSpinnerShown = false;
        this.alertSubscriptionInfo.showErrorAlert(error);
      });
    //}, simulatedServerResponseDelay);

  }

  ngAfterViewInit () {
    this.mediaChangeSubscription = this.mediaObserver.media$.subscribe( (change: MediaChange) => {
      this.activeMediaQuery = change.mqAlias;
      console.log(change.mqAlias);
      // console.log(activeMediaQuery);

      // if (change.mqAlias === 'sm') {
      //     // Here goes code for update data in xs or sm (small screen)
      // } else if(change.mqAlias === 'xs'){
      //     // Here goes code for update data in gt-sm (bigger screen)
      // }
    });
  }

  ngOnDestroy() {
    if(this.mediaChangeSubscription!=null){
      this.mediaChangeSubscription.unsubscribe();
    }
  }

  onSubmitFormBeforeValidation(formValue){
    // console.log('CompanySetupComponent.onSubmitFormBeforeValidation');
    // console.log(this.form.form.valid);
    this.alertBasicInfo.hideAlert();
  }

  onSubmitFormAfterValidation(formValue){
    //console.log('CompanySetupComponent.onSubmitFormAfterValidation');
    //this.stepper.next();
    //console.log(formValue);

    // this.dialogRefMessage = this.dialogService.open(MessageComponent, {
    //   closeOnBackdropClick:false,
    //   context: {
    //     content: '<div class="spinner-border" role="status"><span class="sr-only"></span></div> &nbsp; Waiting for server response...'
    //   }
    // });
    this.modalMessage.showModal({
      content: this.configService.modalContentAwaitingServerResponse
    });

    this.companyService.editCompany(this.authService.getCredentials().companyID, formValue)
    .subscribe(res => {
      //setTimeout(() => {
        if(res.statusCode='200'){
          if(res.message!=null){
            this.alertBasicInfo.showAlert(res.message, 'info');
          }
          this.stepper.next();
          this.modalMessage.hideModal();

          this.redirectCountdownInterval = setInterval(()=>{
            this.redirectCountdownTimer -= 1;
            if(this.redirectCountdownTimer == 0){
              this.redirectToConsole();
              clearInterval(this.redirectCountdownInterval);
            }
          }, 1000);
        }
      //}, simulatedServerResponseDelay);
      
    },
    error=>{
      //setTimeout(() => {
        // this.isLoadingSpinnerShown = false;
        this.alertBasicInfo.showErrorAlert(error);

        this.modalMessage.hideModal();
      //}, simulatedServerResponseDelay);
    });
  }

  onClickStepSubscriptionInfoPrevButton(){
    //console.log('CompanySetupComponent.onClickStepSubscriptionInfoPrevButton');
    this.stepper.previous();
    this.stepSubscriptionInfo.reset();
  }

  onClickStepBasicInfoNextButton(){
    //console.log('CompanySetupComponent.onClickStepBasicInfoNextButton');
    this.form.onSubmitForm();
    
  }

  redirectToConsole(){
    this.router.navigate(["/console"]);
  }

}
