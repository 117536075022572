import { Injectable } from '@angular/core';
import { FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class InputValidationService
{
    constructor() {}

    softwareKeyFormatRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{20}$/;
    passwordFormatRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{6,12}$/;
    emailFormatRegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    dateFormatRegExp = /^[\d]{4}-[\d]{2}-[\d]{2}$/;
    userIDFormatRegExr = /^[0-9]{7}$/;
    adminIDFormatRegExr = /^a[0-9]{4,}$/;

    formatValidator(nameRe: RegExp): ValidatorFn
    {
        return (control: AbstractControl): { [key: string]: any } | null =>
        {
            //console.log(nameRe + ", " + control.value);
            const formatValid = nameRe.test(control.value);
            //console.log(control.value + " : " + formatValid);
            return formatValid ? null : { 'format': true };
        };
    }

    matchPasswordsValidator(formGroup: FormGroup)
    {
        let password = formGroup.get('password').value;
        let confirmPassword = formGroup.get('confirmPassword').value;

        const valid = password == confirmPassword;
        return valid ? null : { 'matchPasswords': true }
    }
}
