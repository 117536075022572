/*
 * The root NgModule
 */

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';

import {
  NbMenuModule,
  NbSidebarModule,
  NbThemeModule,
  NbDialogModule
} from '@nebular/theme';

import { CORPORATE_THEME } from 'src/app/nb-styles/theme.corporate';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AuthInterceptor, UnauthorizedInterceptor } from 'src/app/interceptors/auth.interceptor';
import { DisableCacheInterceptor } from 'src/app/interceptors/disable-cache.interceptor';
import { CompanySetupModule } from 'src/app/company-setup/company-setup.module';

@NgModule({
  declarations: [
    AppComponent

  ],
  imports: [
    BrowserModule,
    
    HttpClientModule,
    BrowserAnimationsModule,

    CompanySetupModule,
    AppRoutingModule,
    FlexLayoutModule,
    NbThemeModule.forRoot(
      {
        name: 'corporate',
      },
      [ CORPORATE_THEME ],
    ),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DisableCacheInterceptor,
      multi: true
    },
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
