import { Injectable } from '@angular/core';
import { appServerProtocol, appServerIPAddress, appServerPort } from '../global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ProcessHTTPMsgService } from './process-httpmsg.service';
import { createGetDataURL, simulatedServerResponseDelay } from 'src/app/global';

@Injectable({ providedIn: 'root' })
export class DeveloperService
{
    constructor(private http: HttpClient, private processHTTPMsgService: ProcessHTTPMsgService) { }

    registerCompany(companyInfo: any): Observable<any>
    {
        console.log('DeveloperService.registerCompany');

        var url = appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/developers/addNewCompany';
        const postData = { companyInfo: companyInfo };
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

        return this.http.post<any>(url, postData, httpOptions)
            .pipe(
                map(res =>
                {
                    console.log(res);
                    return res;
                }),
                delay(simulatedServerResponseDelay),
                catchError(error => this.processHTTPMsgService.handleError(error))
            );
    }

    getCompanies(): Observable<any>
    {
        console.log('DeveloperService.getCompanies');

        var url = appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/developers/getCompanies';

        return this.http.get<any>(url)
            .pipe(
                map(res => res.data ),
                delay(simulatedServerResponseDelay),
                catchError(error => this.processHTTPMsgService.handleError(error))
            );
    }

    getCompany(companyID: string): Observable<any>
    {
        console.log('DeveloperService.getCompany');

        var url = appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/developers/getCompany?companyID=' + companyID;

        return this.http.get<any>(url)
            .pipe(
                map(res => res.data ),
                delay(simulatedServerResponseDelay),
                catchError(error => this.processHTTPMsgService.handleError(error))
            );
    }

    editCompany(companyID: string, updates: any): Observable<any>
    {
        console.log('DeveloperService.editCompany');

        var url = appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/developers/editCompany';

        const postData =
        {
            model: 'Company',
            filter: { '_id': companyID },
            updates: updates
        };

        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

        return this.http.post<any>(url, postData, httpOptions)
            .pipe(
                map(res => { return res; }),
                delay(simulatedServerResponseDelay),
                catchError(error => this.processHTTPMsgService.handleError(error))
            );
    }

    deleteCompany(companyID: string): Observable<any>
    {
        console.log('DeveloperService.deleteCompany');

        var url = appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/developers/deleteCompany?companyID=' + companyID;
        const postData = { _id: companyID };
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

        return this.http.post<any>(url, postData, httpOptions)
            .pipe(
                map(res => { return res; }),
                delay(simulatedServerResponseDelay),
                catchError(error => this.processHTTPMsgService.handleError(error))
            );
    }

    getSoftwareKeys(): Observable<any>
    {
        console.log('DeveloperService.getSoftwareKeys');

        var url = appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/developers/getSoftwareKeys';

        return this.http.get<any>(url)
            .pipe(
                map(res => { console.log(res); return res.softwareKeysList }),
                delay(simulatedServerResponseDelay),
                catchError(error => this.processHTTPMsgService.handleError(error))
            );
    }

    getCompanyIRISVersion(): Observable<any>
    {
        console.log('DeveloperService.getCompanyIRISVersion');

        const url = createGetDataURL('admins', 'CompanyIrisVersion', null, [], [{ path: 'company', model: 'Company' }]);
        console.log(url);

        return this.http.get<any>(url)
            .pipe(
                map(res => { return res.data; }),
                delay(simulatedServerResponseDelay),
                catchError(error => this.processHTTPMsgService.handleError(error)
            ));
    }

    getUsage(model: string, filter = null, projection: any[] = null, populate: any[] = null, options = null): Observable<any> {
        //var url = appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/admins/web/data?model=Admin&filter=' + JSON.stringify({ 'lastActiveTime': { '$gt': new Date('2022-10-01').toISOString() } });
        var url = appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/admins/web/data?model=' + model;

        if (filter != null) {
            url = url + '&filter=' + JSON.stringify(filter);
        }

        if (projection != null && projection.length > 0) {
            url += '&select=';
            projection.forEach(obj => {
                url += obj;
                url += ',';
            });
            url = url.slice(0, -1);
        }

        if (populate != null && populate.length > 0) {
            url += '&populate=';
            populate.forEach(obj => {
                url += JSON.stringify(obj);
                url += '|';
            });
            url = url.slice(0, -1);
        }

        if (options != null) {
            url = url + '&options=' + JSON.stringify(options);
        }

        console.log(url);

        return this.http.get<any>(url)
            .pipe(
                map(res => res.data),
                delay(simulatedServerResponseDelay),
                catchError(error => this.processHTTPMsgService.handleError(error))
            );
    }

    /*registerAdmin(adminInfo: any): Observable<any>
    {
        console.log('CompanyService.registerAdmin');

        var url = appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/companies/web/registerAdmin';

        const postData = {
            adminInfo: adminInfo
        };

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http.post<any>(url, postData, httpOptions)
            .pipe(
                map(res => {
                    console.log(res);
                    return res;
                }),
                delay(simulatedServerResponseDelay),
                catchError(error => this.processHTTPMsgService.handleError(error))
            );
    }

    deleteAdmin(adminID: string): Observable<any>
    {
        var url = appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/companies/web/deleteAdmin';

        const postData = {
            adminID: adminID
        };

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http.post<any>(url, postData, httpOptions)
            .pipe(
                map(res => {
                    console.log(res);
                    return res;
                }),
                delay(simulatedServerResponseDelay),
                catchError(error => this.processHTTPMsgService.handleError(error))
            );
    }

    getAdmins(filter = null, projection: any[] = null, populate: any[] = null, options = null): Observable<any> {
        var url = appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/companies/web/data?model=Admin';
        if (filter != null) {
            url = url + '&filter=' + JSON.stringify(filter);
        }

        if (projection != null && projection.length > 0) {
            url += '&select=';
            projection.forEach(obj => {
                url += obj;
                url += ',';
            });
            url = url.slice(0, -1);
        }

        if (populate != null && populate.length > 0) {
            url += '&populate=';
            populate.forEach(obj => {
                url += JSON.stringify(obj);
                url += '|';
            });
            url = url.slice(0, -1);
        }

        if (options != null) {
            url = url + '&options=' + JSON.stringify(options);
        }

        return this.http.get<any>(url)
            .pipe(
                map(res => res.data),
                delay(simulatedServerResponseDelay),
                catchError(error => this.processHTTPMsgService.handleError(error))
            );
    }

    getAdmin(adminID: string, projection: any[] = null, populate: any[] = null, options = null): Observable<any> {
        var url = appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/companies/web/data?model=Admin&filter=' + JSON.stringify({ 'adminID': adminID });

        if (projection != null && projection.length > 0) {
            url += '&select=';
            projection.forEach(obj => {
                url += obj;
                url += ',';
            });
            url = url.slice(0, -1);
        }

        if (populate != null && populate.length > 0) {
            url += '&populate=';
            populate.forEach(obj => {
                url += JSON.stringify(obj);
                url += '|';
            });
            url = url.slice(0, -1);
        }

        if (options != null) {
            url = url + '&options=' + JSON.stringify(options);
        }

        return this.http.get<any>(url)
            .pipe(
                map(res => {
                    return res.data[0]
                }),
                delay(simulatedServerResponseDelay)
            );
    }

    editAdmin(adminID: string, updates): Observable<any> {
        var url = appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/companies/web/editAdmin';

        const postData = {
            filter: { 'adminID': adminID },
            updates: updates
        };

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http.post<any>(url, postData, httpOptions)
            .pipe(
                map(res => {
                    console.log(res);
                    return res;
                }),
                delay(simulatedServerResponseDelay),
                catchError(error => this.processHTTPMsgService.handleError(error))
            );
    }

    resendConfirmationEmailToAdmin(email): Observable<any> {

        const url = appServerProtocol + "://" + appServerIPAddress + ':' + appServerPort + '/companies/web/resendConfirmationEmailToAdmin';

        const postData = {
            'email': email
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http.post<any>(url, postData, httpOptions)
            .pipe(
                map(res => {
                    console.log(res);
                    return res;
                }),
                delay(simulatedServerResponseDelay),
                catchError(error => this.processHTTPMsgService.handleError(error))
            );
    }*/
}
