/*
 *  The company-profile-form component that renders a company profile form.
 */

import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { InputValidationService } from 'src/app/services/input-validation.service';
import { getSubscriptionExpiryDate, dateToYYYYMMDDStr, forceDateToUTC, addFormControlError } from 'src/app/global';
import { markFormGroupDirty } from 'src/app/global';

import { DeveloperService } from 'src/app/services/developer.service';

@Component(
{
    selector: 'app-company-profile-form',
    templateUrl: './company-profile-form.component.html',
    styleUrls: ['./company-profile-form.component.scss']
})
export class CompanyProfileFormComponent implements OnInit
{
    constructor(
        private inputValidationService: InputValidationService,
        private developerService: DeveloperService,
        private fb: FormBuilder
    ) {}

    form: FormGroup;

    formData;
    formOptions;

    softwareKeys: any[];

    minSubStart: string = dateToYYYYMMDDStr(new Date());

    formErrors =
    {
        '_id': '',
        'name': '',
        'headOfficePhysicalAddress': '',
        'terminalAddress': '',
        'mailingAddress': '',
        'division': '',
        'softwareKey': '',
        'subscription': '',
        'subPeriod': '',
        'subUserQuota': '',
        'subscriptionStartDate': '',
        'subscriptionExpiryDate': ''
    };

    formValidationMessages =
    {
        '_id':
        {
            'required': 'Company ID is required.',
            'format': 'Invalid company ID format.'
        },
        'name':
        {
            'required': 'Company name is required.',
            'format': 'Invalid company name format.'
        },
        'headOfficePhysicalAddress':
        {
            'required': 'Head office physical address is required.',
            'format': 'Invalid head office physical address format.'
        },
        'terminalAddress':
        {
            'required': 'Terminal address is required.',
            'format': 'Invalid terminal address format.'
        },
        'mailingAddress':
        {
            'required': 'Mailing address is required.',
            'format': 'Invalid mailing address format.'
        },
        'division':
        {
            'required': 'Division is required.',
            'format': 'Invalid division format.'
        },
        'softwareKey':
        {
            'required': 'Software key is required.',
            'format': 'Invalid software key format.'
        },
        'subscription':
        {
            'required': 'Subscription is required.',
            'format': 'Invalid subscription format.'
        },
        'subPeriod':
        {
            'required': 'Subscription Period is required.',
            'format': ''
        },
        'subUserQuota':
        {
            'required': 'User Quota is required.',
            'format': ''
        },
        'subscriptionStartDate':
        {
            'required': 'Subscription start date is required.',
            'format': 'Invalid subscription start date format.',
            'invalidDate': 'Subscription cannot start in the past'
        },
        'subscriptionExpiryDate':
        {
            'required': 'Subscription expiry date is required.',
            'format': 'Invalid subscription expiry date format.'
        }
    };

    formFormatRegExps =
    {
        '_id': /^[a-f\d]{24}$/i, //hexadecimal ObjectId
        'name': /^.{1,50}$/,
        'headOfficePhysicalAddress': /^.{1,50}$/,
        'terminalAddress': /^.{1,50}$/,
        'mailingAddress': /^.{1,50}$/,
        'division': /^.{1,50}$/,
        'softwareKey': /^[a-f\d]{24}$/i, //validate for ObjectId rather than key value //this.inputValidationService.softwareKeyFormatRegExp,
        'subscription': /^[\d]+-[\d]+[yYmMdD]$/,
        'subPeriod': /^[\d]{1,2}[ymd]$/i,
        'subUserQuota': /^[\d]{1,3}$/,
        'subscriptionStartDate': this.inputValidationService.dateFormatRegExp,
        'subscriptionExpiryDate': this.inputValidationService.dateFormatRegExp,
    };

    @Input()
    onSubmitFormAfterValidation: any;

    @Input()
    onSubmitFormBeforeValidation: any;

    ngOnInit() {}

    createForm(data, options = null)
    {
        this.formData = data;
        this.formOptions = options;

        if (['company-profile-developer', 'register-company'].includes(this.formOptions.view))
        {
            this.developerService.getSoftwareKeys().subscribe(softwareKeyList =>
            {
                this.softwareKeys = softwareKeyList;
                if (this.formData.softwareKey) this.softwareKeys.unshift(this.formData.softwareKey);
            });
        }

        const formData = jQuery.extend({}, this.formData);

        if (['company-profile-user', 'company-profile-admin', 'company-profile-company', 'company-profile-developer'].includes(this.formOptions.view))
        {
            formData.subscription = this.formData.subscription.subscriptionID;
            //formData.softwareKey = (this.formData.softwareKey ? this.formData.softwareKey.key : "");

            if (this.formData.softwareKey)
            {
                formData.softwareKey = this.formData.softwareKey[(this.formOptions.view == 'company-profile-developer' ? "_id" : "key")];
            }
            else
            {
                formData.softwareKey = "";
            }

            formData.subPeriod = this.formData.subscription.period;
            formData.subUserQuota = this.formData.subscription.userQuota;
            formData.subscriptionStartDate = dateToYYYYMMDDStr(new Date(formData.subscriptionStartDate));

            var subscriptionExpiryDate = getSubscriptionExpiryDate(new Date(forceDateToUTC(formData.subscriptionStartDate)), this.formData.subscription.period);
            formData.subscriptionExpiryDate = dateToYYYYMMDDStr(subscriptionExpiryDate);
        }

        if (this.formOptions.view == "register-company")
        {
            formData.subscriptionStartDate = dateToYYYYMMDDStr(new Date());
        }

        //console.log(formData);
        var controlsConfig = {};
        var isDisabled = false;

        if (['company-profile-user', 'company-profile-admin'].includes(this.formOptions.view))
        {
            for (const field in this.formErrors)
            {
                if (this.formErrors.hasOwnProperty(field))
                {
                    controlsConfig[field] = [{ value: formData[field], disabled: true }, [Validators.required, this.inputValidationService.formatValidator(this.formFormatRegExps[field])]];
                }
            }
        }
        else if (['company-profile-company'].includes(this.formOptions.view))
        {
            for (const field in this.formErrors)
            {
                if (this.formErrors.hasOwnProperty(field))
                {
                    isDisabled = ['_id', 'softwareKey', 'subscription', 'subscriptionStartDate', 'subscriptionExpiryDate'].includes(field);
                    controlsConfig[field] = [{ value: formData[field], disabled: isDisabled }, [Validators.required, this.inputValidationService.formatValidator(this.formFormatRegExps[field])]];
                }
            }
        }
        else if (['company-profile-developer', 'register-company'].includes(this.formOptions.view))
        {
            for (const field in this.formErrors)
            {
                if (field == "subscription") continue;

                if (this.formErrors.hasOwnProperty(field))
                {
                    isDisabled = ['_id', 'subscriptionExpiryDate'].includes(field);
                    controlsConfig[field] = [{ value: formData[field], disabled: isDisabled }, [Validators.required, this.inputValidationService.formatValidator(this.formFormatRegExps[field])]];
                }
            }
        }
        else if (['company-setup'].includes(this.formOptions.view))
        {
            for (const field in this.formErrors)
            {
                if (this.formErrors.hasOwnProperty(field))
                {
                    if (['softwareKey', 'subscription', 'subscriptionStartDate', 'subscriptionExpiryDate'].includes(field)) continue;
                    controlsConfig[field] = [{ value: formData[field], disabled: false }, [Validators.required, this.inputValidationService.formatValidator(this.formFormatRegExps[field])]];
                }
            }
        }

        this.form = this.fb.group(controlsConfig);
        this.form.valueChanges.subscribe(data => this.onFormValueChanged(data));
    }

    onFormValueChanged(data?: any)
    {
        if (!this.form) return;
        this.updateFormErrors();
    }

    updateFormErrors()
    {
        for (const field in this.formErrors)
        {
            if (this.formErrors.hasOwnProperty(field))
            {
                // clear previous error message (if any)
                this.formErrors[field] = '';
                const control = this.form.get(field);

                if (control && control.dirty && !control.valid)
                {
                    const messages = this.formValidationMessages[field];
                    for (const key in control.errors)
                    {
                        if (control.errors.hasOwnProperty(key))
                        {
                            //this.loginFormUserErrors[field] += messages[key] + ' ';
                            console.log(key + ' => ' + messages[key]);
                            this.formErrors[field] = messages[key];
                            break;
                        }
                    }
                }
            }
        }
    }

    onSubmitForm()
    {
        console.log('onSubmitForm');
        // console.log(this.adminProfileForm.getRawValue());
        if (this.onSubmitFormBeforeValidation != null)
        {
            this.onSubmitFormBeforeValidation(this.form.value);
        }

        if (!this.form) return;
        markFormGroupDirty(this.form);

        this.updateFormErrors();

        console.log(this.form.invalid);
        if (this.form.invalid) return;

        console.log(this.form.value);
        if (this.onSubmitFormAfterValidation != null)
        {
            //force date to UTC
            this.form.value["subscriptionStartDate"] = forceDateToUTC(this.form.value["subscriptionStartDate"]);
            this.onSubmitFormAfterValidation(this.form.value);
        }

        for (const field in this.form.value)
        {
            if (this.form.value.hasOwnProperty(field))
            {
                this.formData[field] = this.form.value[field];
            }
        }
    }

    onChangeSubPeriod(period)
    {
        this.setSubscriptionExpiryDate(getSubscriptionExpiryDate(new Date(forceDateToUTC(this.form.value.subscriptionStartDate)), period));
    }

    onChangeSubStart(startDate)
    {
        /*
         * Required because Angular uses min attribute to restrict popup selection,
         * but not manual entry (as in vanilla HTML)
         *
         * Should only validate when *this* field is modified
        */
        if (this.form.controls['subscriptionStartDate'].value < this.minSubStart)
        {
            console.log('Invalid start date entered.  Cannot be in the past!');
            addFormControlError(this.form.controls['subscriptionStartDate'], 'invalidDate');
        }

        this.setSubscriptionExpiryDate(getSubscriptionExpiryDate(new Date(forceDateToUTC(startDate)), this.form.value.subPeriod));
    }

    setSubscriptionExpiryDate(expiryDate)
    {
        this.form.controls.subscriptionExpiryDate.setValue(dateToYYYYMMDDStr(expiryDate));
    }
}
